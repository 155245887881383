:root {
  /* Light theme (default) */
  --bg-color: #ffffff;
  --text-color: #333333;
  --heading-color: #003366;
  --form-bg: #f5f5f5;
  --form-border: #ddd;
  --input-bg: #ffffff;
  --input-border: #ddd;
  --card-bg: #ffffff;
  --card-shadow: rgba(0, 0, 0, 0.1);
  --secondary-bg: #f5f5f5;
  --secondary-text: #666666;
  --accent-color: #0062cc;
  --accent-hover: #004e9e;
  --table-header-bg: #f5f5f5;
  --table-border: #eee;
  --button-disabled: #cccccc;
  --filter-toggle-bg: #f0f0f0;
  --filter-toggle-color: #555;
  --error-bg: #ffe6e6;
  --error-color: #d9534f;
  --info-bg: #d9edf7;
  --info-color: #31708f;
}

/* Dark theme - Improved */
body.dark-theme {
  --bg-color: #0f172a; /* Deep blue-black */
  --text-color: #e2e8f0;
  --heading-color: #38bdf8; /* Bright sky blue */
  --form-bg: #1e293b; /* Darker slate blue */
  --form-border: #334155;
  --input-bg: #1e293b;
  --input-border: #475569;
  --card-bg: #1e293b;
  --card-shadow: rgba(0, 0, 0, 0.5);
  --secondary-bg: #334155; /* Medium slate blue */
  --secondary-text: #94a3b8;
  --accent-color: #0ea5e9; /* Sky blue */
  --accent-hover: #0284c7; /* Darker sky blue */
  --table-header-bg: #334155;
  --table-border: #475569;
  --button-disabled: #64748b;
  --filter-toggle-bg: #334155;
  --filter-toggle-color: #94a3b8;
  --error-bg: #450a0a; /* Deep red */
  --error-color: #fca5a5; /* Light red */
  --info-bg: #0c4a6e; /* Deep blue */
  --info-color: #7dd3fc; /* Light blue */
  --leaflet-popup-bg: #1e293b; /* Dark background for Leaflet popups */
  --leaflet-popup-text: #e2e8f0; /* Light text for Leaflet popups */
}

/* Add global transition for smooth theme switching */
* {
  transition: background-color 0.3s ease, 
              color 0.3s ease,
              border-color 0.3s ease,
              box-shadow 0.3s ease;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  min-height: 100vh;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1 {
  color: var(--heading-color);
  margin-bottom: 2rem;
  text-align: center;
}

.search-form {
  background-color: var(--form-bg);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px var(--card-shadow);
  border: 1px solid var(--form-border);
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
  flex: 1;
}

.form-group.half {
  flex: 0.5;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.optional-label {
  font-weight: normal;
  font-style: italic;
  color: var(--secondary-text);
  font-size: 0.9em;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--input-bg);
  color: var(--text-color);
}

select {
  background-color: var(--input-bg);
  color: var(--text-color);
  border: 1px solid var(--input-border);
}

.filter-toggle {
  background-color: var(--filter-toggle-bg);
  color: var(--filter-toggle-color);
  border: 1px solid var(--form-border);
  margin-bottom: 1rem;
}

.filter-toggle:hover {
  background-color: var(--secondary-bg);
}

.advanced-filters {
  background-color: var(--secondary-bg);
  border: 1px solid var(--form-border);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.advanced-filters h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--secondary-text);
  font-size: 1.1rem;
}

.brand-filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.brand-checkbox {
  display: flex;
  align-items: center;
}

.brand-checkbox input[type="checkbox"] {
  width: auto;
  margin-right: 0.5rem;
}

.brand-checkbox label {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 0.9rem;
}

.search-button {
  position: relative;
  background-color: var(--accent-color);
  color: white;
  padding: 0.75rem 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loading styles removed in favor of simpler opacity transition */

button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

button:hover {
  background-color: var(--accent-hover);
}

button:disabled {
  background-color: var(--button-disabled);
  cursor: not-allowed;
}

.error, .api-error, .api-status {
  color: var(--error-color);
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: var(--error-bg);
  border-radius: 4px;
}

.api-status {
  color: var(--info-color);
  background-color: var(--info-bg);
  border: 1px solid var(--info-color);
  text-align: center;
  margin-bottom: 1rem;
}

.api-error {
  margin-bottom: 1rem;
  font-weight: bold;
  border: 1px solid var(--error-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.api-error-details {
  font-size: 0.9em;
  font-weight: normal;
  margin: 0.5rem 0;
}

.api-error code {
  background-color: var(--form-bg);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  border: 1px solid var(--form-border);
  font-family: monospace;
}

.retry-button {
  margin-top: 0.5rem;
  padding: 0.3rem 0.8rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9em;
}

.retry-button:hover {
  background-color: var(--accent-hover);
}

.loading, .no-results {
  text-align: center;
  color: var(--secondary-text);
  padding: 2rem 0;
}

.loading {
  text-align: center;
  color: var(--secondary-text);
  padding: 2rem 0;
  font-style: italic;
}

.results-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.results-summary {
  background-color: var(--secondary-bg);
  padding: 0.75rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: var(--secondary-text);
  text-align: center;
  border: 1px solid var(--form-border);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

.pagination-controls button {
  padding: 0.5rem 1rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: var(--button-disabled);
  cursor: not-allowed;
}

.page-info {
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.sort-controls {
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  border: 1px solid var(--form-border);
}

.sort-controls label {
  margin-right: 1rem;
  font-weight: bold;
}

.sort-controls select {
  padding: 0.5rem;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--input-bg);
  color: var(--text-color);
  flex-grow: 1;
  max-width: 300px;
}

.hotel-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.hotel-card {
  background-color: var(--card-bg);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px var(--card-shadow);
  transition: all 0.3s ease;
  border: 1px solid var(--form-border);
  position: relative;
  overflow: hidden;
}

.hotel-card:hover {
  box-shadow: 0 8px 16px var(--card-shadow);
  transform: translateY(-3px);
}

/* Highlight animation for when a hotel is selected from the map */
@keyframes highlight-pulse {
  0% { box-shadow: 0 0 0 0 rgba(0, 98, 204, 0.7); }
  70% { box-shadow: 0 0 0 15px rgba(0, 98, 204, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 98, 204, 0); }
}

.hotel-card.highlight-card {
  animation: highlight-pulse 1.5s 2;
  box-shadow: 0 8px 16px var(--card-shadow);
  transform: translateY(-3px);
  border-color: var(--accent-color);
  position: relative;
  z-index: 10;
}

/* Add subtle accent to the top of each card */
.hotel-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, var(--accent-color), var(--heading-color));
  border-radius: 4px 4px 0 0;
}

.hotel-header {
  margin-bottom: 1rem;
}

.hotel-header h3 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  color: var(--heading-color);
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  transition: color 0.3s ease;
}

.hotel-card:hover .hotel-header h3 {
  color: var(--accent-color);
}

.location {
  color: var(--secondary-text);
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.location::before {
  content: '📍';
  margin-right: 5px;
  font-size: 0.9em;
}

.brand {
  color: var(--secondary-text);
  font-style: italic;
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--secondary-bg);
  border-radius: 4px;
  opacity: 0.85;
}

.price-summary {
  border-top: 1px solid var(--table-border);
  padding-top: 1.2rem;
  margin-bottom: 1.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.price {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  background-color: var(--secondary-bg);
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.price:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px var(--card-shadow);
}

.label {
  font-weight: bold;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 5px;
  color: var(--secondary-text);
}

.value {
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 600;
}

/* Style specifically for the value per point to highlight it */
.price.value .value {
  color: var(--accent-color);
}

.toggle-dates {
  width: 100%;
  background-color: var(--form-bg);
  border: 1px solid var(--form-border);
  border-radius: 8px;
  padding: 0.7rem;
  cursor: pointer;
  font-size: 0.95rem;
  color: var(--text-color);
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-dates:hover {
  background-color: var(--filter-toggle-bg);
  transform: translateY(-2px);
  box-shadow: 0 3px 8px var(--card-shadow);
}

.toggle-dates.expanded {
  background-color: var(--accent-color);
  color: white;
}

/* Add icon for toggle button */
.toggle-dates::before {
  content: '📅';
  margin-right: 8px;
  font-size: 1em;
}

.date-details {
  margin-top: 1.5rem;
  overflow-x: auto;
  border-radius: 8px;
  border: 1px solid var(--form-border);
  box-shadow: 0 4px 12px var(--card-shadow);
}

.date-details table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  overflow: hidden; /* For border radius to work */
}

.date-details th,
.date-details td {
  text-align: left;
  padding: 0.7rem;
  border-bottom: 1px solid var(--table-border);
}

.date-details th {
  background-color: var(--accent-color);
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.05em;
}

.date-details tr:last-child td {
  border-bottom: none;
}

/* Add hover effect to table rows */
.date-details tr:not(:first-child):hover {
  background-color: var(--secondary-bg);
}

/* Highlight value column */
.date-details td:nth-child(4) {
  color: var(--accent-color);
  font-weight: 600;
}

.no-dates-message {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: var(--secondary-bg);
  border: 1px solid var(--form-border);
  border-radius: 8px;
  text-align: center;
  color: var(--secondary-text);
  font-style: italic;
  box-shadow: 0 2px 8px var(--card-shadow);
}

/* Theme Toggle */
.theme-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: var(--card-bg);
  padding: 8px 16px;
  border-radius: 30px;
  box-shadow: 0 2px 10px var(--card-shadow);
  border: 1px solid var(--form-border);
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.9;
}

.theme-toggle:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--card-shadow);
  opacity: 1;
}

.theme-toggle-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.theme-toggle:hover .theme-toggle-icon {
  transform: rotate(12deg);
}

.theme-toggle-text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--text-color);
  letter-spacing: 0.5px;
}

@media (max-width: 600px) {
  .theme-toggle-text {
    display: none;
  }
  
  .theme-toggle {
    padding: 10px;
    border-radius: 50%;
  }
  
  .theme-toggle-icon {
    margin-right: 0;
    font-size: 1.3rem;
  }
}

/* Map Styles */
.view-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

/* Fallback colors for browsers without CSS variables */
.view-toggle button {
  background-color: #f5f5f5;
  color: #333333;
  border: 1px solid #ddd;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  /* CSS variable support */
  background-color: var(--secondary-bg, #f5f5f5);
  color: var(--text-color, #333333);
  border: 1px solid var(--form-border, #ddd);
}

.view-toggle button.active {
  background-color: #0062cc;
  color: white;
  /* CSS variable support */
  background-color: var(--accent-color, #0062cc);
  color: white;
}

.view-toggle button:first-child {
  border-radius: 4px 0 0 4px;
}

.view-toggle button:last-child {
  border-radius: 0 4px 4px 0;
}

.hotel-map {
  width: 100%;
  box-shadow: 0 2px 5px var(--card-shadow);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}

/* Custom marker styles */
.marker-price {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, box-shadow 0.2s;
}

.marker-price:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
}

/* Value per point color indicators:
   Green: High value (>= 0.8 cpp)
   Orange: Medium value (0.5-0.8 cpp)
   Red: Low value (< 0.5 cpp) */
.marker-price.green {
  background-color: #27ae60;
}

.marker-price.orange {
  background-color: #e67e22;
}

.marker-price.red {
  background-color: #e74c3c;
}

.marker-price.gray {
  background-color: #7f8c8d;
}

.hotel-popup {
  width: 320px;
  padding: 12px;
  max-height: 450px;
  overflow-y: auto;
  background-color: white;
  color: #333333;
}

/* Specific overrides for dark mode */
body.dark-theme .leaflet-popup-content-wrapper {
  background-color: #1e293b;
  color: #e2e8f0;
}

body.dark-theme .leaflet-popup-tip {
  background-color: #1e293b;
}

body.dark-theme .hotel-popup {
  background-color: #1e293b;
  color: #e2e8f0;
}

.hotel-popup h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #003366;
}

body.dark-theme .hotel-popup h3 {
  color: #38bdf8;
}

.hotel-popup h4 {
  margin: 15px 0 8px 0;
  font-size: 14px;
  color: #003366;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

body.dark-theme .hotel-popup h4 {
  color: #38bdf8;
  border-bottom-color: #334155;
}

.hotel-popup p {
  margin: 5px 0;
  font-size: 13px;
  line-height: 1.4;
  color: #333333;
}

body.dark-theme .hotel-popup p {
  color: #e2e8f0;
}

.popup-location {
  font-weight: 500;
}

.popup-brand {
  font-style: italic;
  color: #666666;
  font-size: 12px;
  display: inline-block;
  padding: 2px 6px;
  background-color: #f5f5f5;
  border-radius: 3px;
  margin-top: 0;
}

body.dark-theme .popup-brand {
  color: #94a3b8;
  background-color: #334155;
}

.popup-value-highlight {
  background-color: #f5f5f5;
  padding: 8px 12px;
  margin: 10px 0;
  border-radius: 4px;
  border-left: 3px solid #0062cc;
}

body.dark-theme .popup-value-highlight {
  background-color: #334155;
  border-left-color: #0ea5e9;
}

.popup-value {
  font-size: 16px;
  font-weight: bold;
  color: #0062cc;
  margin-left: 5px;
}

body.dark-theme .popup-value {
  color: #0ea5e9;
}

.popup-value-label {
  font-size: 11px;
  color: #666666;
  margin-left: 3px;
}

body.dark-theme .popup-value-label {
  color: #94a3b8;
}

.popup-dates-table {
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #ffffff;
}

body.dark-theme .popup-dates-table {
  border-color: #334155;
  background-color: #1e293b;
}

.popup-dates-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  color: #333333;
}

body.dark-theme .popup-dates-table table {
  color: #e2e8f0;
}

.popup-dates-table th {
  background-color: #0062cc;
  color: white;
  padding: 6px;
  position: sticky;
  top: 0;
  text-align: left;
  font-weight: 500;
  font-size: 11px;
}

body.dark-theme .popup-dates-table th {
  background-color: #0ea5e9;
}

.popup-dates-table td {
  padding: 6px;
  border-bottom: 1px solid #eee;
  white-space: nowrap;
}

body.dark-theme .popup-dates-table td {
  border-bottom-color: #334155;
}

.popup-dates-table tr:last-child td {
  border-bottom: none;
}

.popup-dates-table tr:hover {
  background-color: #f5f5f5;
}

body.dark-theme .popup-dates-table tr:hover {
  background-color: #334155;
}

.popup-more-dates {
  text-align: center;
  padding: 5px;
  background-color: #f5f5f5;
  color: #666666;
  font-size: 11px;
  font-style: italic;
}

body.dark-theme .popup-more-dates {
  background-color: #334155;
  color: #94a3b8;
}

.hotel-popup button {
  background-color: #0062cc;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;
}

body.dark-theme .hotel-popup button {
  background-color: #0ea5e9;
}

.hotel-popup button:hover {
  background-color: #004e9e;
}

body.dark-theme .hotel-popup button:hover {
  background-color: #0284c7;
}

/* General Leaflet popup styles for dark mode */
body.dark-theme .leaflet-popup-content-wrapper,
body.dark-theme .leaflet-popup-tip {
  background-color: var(--leaflet-popup-bg);
  color: var(--leaflet-popup-text);
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.7);
}

/* Map status messages */
.no-coordinates-message, .map-error, .too-many-hotels-message, .many-hotels-info, .map-loading {
  background-color: var(--secondary-bg);
  padding: 30px;
  text-align: center;
  border-radius: 4px;
  color: var(--secondary-text);
  margin: 20px 0;
  border: 1px dashed var(--form-border);
}

.too-many-hotels-message, .many-hotels-info {
  background-color: var(--info-bg);
  color: var(--info-color);
  border: 1px solid var(--info-color);
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.refine-search-button {
  margin-top: 10px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.map-error {
  background-color: var(--error-bg);
  color: var(--error-color);
  border: 1px solid var(--error-color);
}

.map-loading {
  background-color: var(--info-bg);
  color: var(--info-color);
  border: 1px solid var(--info-color);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

/* Map loading overlay */
.map-overlay-container {
  position: relative;
  z-index: 1000;
}

.map-loading-overlay {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 8px 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  pointer-events: none;
}

.map-loading-indicator {
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
  animation: pulse 1.2s infinite;
}

/* Responsive map layout */
@media (max-width: 1200px) {
  .hotel-map {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .hotel-map {
    height: 400px;
  }
  
  .view-toggle button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .hotel-map {
    height: 350px;
  }
  
  .marker-price {
    width: 30px;
    height: 30px;
    font-size: 10px;
  }
  
  .hotel-popup {
    width: 200px;
  }
}